<template>
  <!-- 面向对象设计班 -->
  <div class="vip-page">
    <div sticky-container>
      <div class="page-wrap">
        <div
          class="page-banner"
          :style="{ backgroundImage: 'url(' + img + ')' }"
        >
          <div class="container">
            <div>
              <pageSelect
                :br="br"
                :bantxt="bantxt"
                @changebr="(val) => (br = val)"
              ></pageSelect>
            </div>
            <div class="sch-title">面向对象设计班</div>
            <div class="sch-desc">
              北美OOD面试必修课，全网独特的OOD体系课程<br />
              从对象三要素&amp;UML，到设计原则和23种Design Pattern，OOD案例全讲
            </div>
            <ul class="leet-date leet-sm-space">
              <li>
                <div class="val">7 <span>大</span></div>
                <div class="txt">设计原则</div>
              </li>
              <li>
                <div class="val">12 <span>个</span></div>
                <div class="txt">OOD案例</div>
              </li>
              <li>
                <div class="val">23 <span>种</span></div>
                <div class="txt">设计模式</div>
              </li>
              <li>
                <div class="val">60 <span>天</span></div>
                <div class="txt">答疑服务</div>
              </li>
<!--              <li>-->
<!--                <div class="val">5000 <span>+</span></div>-->
<!--                <div class="txt">学员好评</div>-->
<!--              </li>-->
            </ul>
            <!-- 视频 -->
            <div @click="videoShow = true" class="page-video">
              <img src="../../assets/images/scb/shipin.png" alt="" />
              <div class="v-icon"></div>
              <div class="v-title">课程简介 &amp; 免费试听</div>
            </div>
          </div>
        </div>

        <poly-video
          v-if="videoShow"
          :br="br"
          @closeVideo="videoShow = false"
        ></poly-video>

        <div class="priceBanner">
          <div class="priceBannerArea">
            <div class="priceBannerContent">
              <img src="@/assets/imgs/priceBanner.png" alt="" />
              <div
              	class="price-tip"
                style="
                  color: rgba(0, 0, 0, 0.65);
                  text-align: center;
                  font-size: 12px;
                  position: absolute;
                  right: 82px;
                  top: 15px;
                "
              >
                立即报名，立即开始！报名即学，不浪费一分一秒！
              </div>
              <div class="priceBannerInner">
                <div class="priceLeft">
                  <div
                    class="prices"
                    style=""
                    v-if="comboData.length > 0"
                  >
                    <span style="font-size: 28px; color: #de1306">
                      ${{
                        comboData[0].usddiscountPrice != 0
                          ? comboData[0].usddiscountPrice
                          : comboData[0].usdprice
                      }}</span
                    >
                    <span style="font-size: 16px; color: #e02020"
                      >&nbsp;/&nbsp;&yen;{{
                        comboData[0].discountPrice != 0
                          ? comboData[0].discountPrice
                          : comboData[0].price
                      }}</span
                    >
                  </div>
                  <div class="pricesTips">
                    <div class="dollar">支持美元分期付款</div>
                    <div class="huabei">支持花呗分期</div>
                  </div>
                </div>

                <div class="priceRight">
                  <el-button class="classHelp" @click="helpVisible = true"
                    >课程咨询</el-button
                  >
                  <el-button class="buyNow" @click="jumptoPrice"
                    >立即购买</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <helpClass
          :visible="helpVisible"
          @setHelpVisible="(val) => (helpVisible = val)"
        ></helpClass>

        <div
          class="sticky"
          v-sticky="stickyEnabled"
          sticky-offset="{top: 0, bottom: 30}"
          sticky-side="both"
          on-stick="onStick"
          sticky-z-index="99920"
        >
          <div class="page-navbar">
            <div class="container">
              <pageStickySelect
                :dr="dr"
                @changedr="(val) => (dr = val)"
              ></pageStickySelect>
              <Menus
                :menus="menuList"
                @TabActiveIndexChange="TabActiveIndexChange"
                :current-index="currentIndex"
              />
              <div v-if="stickyToggle" class="price" style="right: 75px">
                <span
                  style="font-size: 16px; color: #de1306"
                  v-if="comboData.length > 0"
                >
                  ${{
                    comboData[0].usddiscountPrice > 0
                      ? comboData[0].usddiscountPrice
                      : comboData[0].usdprice
                  }}
                </span>
                <span style="font-size: 12px; color: #e02020"
                  >/&yen;{{
                    comboData[0].discountPrice > 0
                      ? comboData[0].discountPrice
                      : comboData[0].price
                  }}</span
                >

                <div class="priceDetail" @click="jumptoPrice">价格详情</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="TabActiveIndex === 'course_intro'">
          <CourseDetail></CourseDetail>
          <UML></UML>
          <OOD></OOD>
          <ObjectOriented></ObjectOriented>
          <UMLDetail></UMLDetail>
          <div class="g-section gof-seciotn" style="margin-bottom: 0">
            <div class="container">
              <div class="gof-main">
                <div class="g-header">
                  <h3>
                    <img src="@/assets/images/beimei/ee.png" alt="" /><span
                      >GoF 23种设计模式逐一讲解，打造完整的设计体系框架</span
                    >
                  </h3>
                  <p>设计模式类型划分，在实际场景中应用，如何写代码</p>
                </div>
                <div class="gof-cells">
                  <div class="gof-row">
                    <div class="gof-box gof-box-hd">
                      <div>创建型</div>
                    </div>
                    <div class="gof-box">
                      <div>工厂方法<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>单例模式</div>
                    </div>
                    <div class="gof-box">
                      <div>原型模式</div>
                    </div>
                    <div class="gof-box">
                      <div>抽象工厂<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>建造者<br />模式</div>
                    </div>
                  </div>
                  <div class="gof-row">
                    <div class="gof-box gof-box-hd">
                      <div>结构型</div>
                    </div>
                    <div class="gof-box">
                      <div>(类适配器)<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>代理模式</div>
                    </div>
                    <div class="gof-box">
                      <div>桥接模式</div>
                    </div>
                    <div class="gof-box">
                      <div>装饰模式</div>
                    </div>
                    <div class="gof-box">
                      <div>外观模式</div>
                    </div>
                    <div class="gof-box">
                      <div>享元模式</div>
                    </div>
                    <div class="gof-box">
                      <div>组合模式</div>
                    </div>
                  </div>
                  <div class="gof-row">
                    <div class="gof-box gof-box-hd">
                      <div>行为型</div>
                    </div>
                    <div class="gof-box">
                      <div>模板方法<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>策略模式</div>
                    </div>
                    <div class="gof-box">
                      <div>命令模式</div>
                    </div>
                    <div class="gof-box">
                      <div>职责链<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>状态模式</div>
                    </div>
                    <div class="gof-box has-btm-line">
                      <div>观察者<br />模式</div>
                    </div>
                  </div>
                  <div class="gof-row" style="padding-left: 190px">
                    <div class="gof-box">
                      <div>解释器<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>备忘录<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>访问者<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>迭代器<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>中介者<br />模式</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--          <EightWrites></EightWrites>-->

          <!-- <course-description></course-description> -->
          <offer></offer>
          <free-system></free-system>
          <div class="g-section leet-jj-section" id="discountPrice">
            <a name="tt-price"> </a>
            <div class="g-header">
              <h3>价格方案</h3>
            </div>
            <div>
              <div class="container">
                <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div
                    class="gg-card"
                    style="margin: 0 auto !important"
                    v-if="comboData && comboData.length > 0"
                  >
                    <el-row>
                      <el-col :span="comboData.length > 1 ? 12 : 24">
                        <div class="gg-yh-cell">
                          <div :class="['gg-yh-grid purple']">
                            <div class="gg-price">
                              ${{
                                comboData[0].usddiscountPrice > 0
                                  ? comboData[0].usddiscountPrice
                                  : comboData[0].usdprice
                              }}
                            </div>
                            <div class="gg-y-price">
                              ¥{{
                                comboData[0].discountPrice > 0
                                  ? comboData[0].discountPrice
                                  : comboData[0].price
                              }}
                            </div>
                            <div
                              class="gg-base-price"
                              v-if="comboData[0].usddiscountPrice != 0"
                            >
                              原价：${{ comboData[0].usdprice }}
                            </div>
                          </div>
                          <div class="gg-yh-body">
                            <div class="tip">
                              对象三要素 & 统一建模语言UML & 7大设计原则<br />23种Design
                              Pattern & 12大OOD经典案例
                            </div>
                            <ul class="gg-yh-item">
                              <li>
                                <i class="iconfont icon-dui"></i
                                >独特的OOD设计体系
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i>经典万能模板
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i>2个月有效期
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i>60天微信群答疑
                              </li>
                            </ul>
                            <div class="yh-foot">
                              <el-button
                                type="buy"
                                @click="buyCombo"
                                class="purple"
                                >立即购买
                              </el-button>
                            </div>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 相关课程推荐 -->
          <div class="g-section">
            <div class="g-header">
              <h3>—— 相关课程推荐 ——</h3>
            </div>
            <div class="container">
              <div class="rel-main" style="padding-bottom: 0">
                <el-row :gutter="30">
                  <el-col
                    :span="12"
                    v-for="(item, index) in recommendClasses"
                    :key="index"
                  >
                    <div
                      class="rel-card hoverUp"
                      @click="changePage(item.courseClassId)"
                    >
                      <div class="rel-pic">
                        <img :src="item.coverUrl" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-desc">
                          {{ item.brief }}
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div v-if="TabActiveIndex == 'course_question'" class="questionArea">
          <presales-consultation :objectId="8"></presales-consultation>
        </div>
        <course-detail :TabActive="TabActiveIndex" :course-i-d="courseId" />
      </div>
    </div>
    <leetDialog></leetDialog>
  </div>
</template>

<script>
import leetDialog from "@/components/leetDialog.vue";
import courseMix from "@/utils/courseMix";
import courseDetail from "@/components/course/courseDetail";
import Menus from "@/components/course/menu";
import offer from "./components/offer";
import freeSystem from "./components/freeSystem";
import presalesConsultation from "./components/presalesConsultation";
import polyVideo from "./components/polyVideo";
import courseDescription from "./components/courseDescription";
import courseMeal from "./components/courseMeal";
import pageSelect from "./components/pageSelect";
import pageStickySelect from "./components/pageStickySelect";
import helpClass from "./components/help";
import { getCountDownClass } from "@/service/index";
import UML from "@/components/course/coursesComponents/UML";
import OOD from "@/components/course/coursesComponents/OOD";
import ObjectOriented from "@/components/course/coursesComponents/ObjectOriented";
import UMLDetail from "@/components/course/coursesComponents/UMLDetail";
import EightWrites from "@/components/course/coursesComponents/eightWrites";
import CourseDetail from "@/components/course/coursesComponents/courseDetail";
import { getCourseBanner } from "@/service";
export default {
  components: {
    leetDialog,
    Menus,
    courseDetail,
    offer,
    freeSystem,
    polyVideo,
    courseDescription,
    presalesConsultation,
    courseMeal,
    pageSelect,
    pageStickySelect,
    helpClass,
    UML,
    OOD,
    ObjectOriented,
    UMLDetail,
    EightWrites,
    CourseDetail,
  },
  mixins: [courseMix],
  data() {
    return {
      stickyToggle: false,
      stickyEnabled: true,
      videoShow: false,
      bantxt: "面向对象设计班",
      dr: 8,
      br: 8,
      txShow: true,
      bzid: 0,
      Pselect: "1",
      img: require("../../assets/images/8-ObjectDesignCourse/面向对象设计bg.png"),

      activeNames: ["1"],
      TabActiveIndex: "course_intro",
      menuList: [
        { name: "班课介绍", index: "0", tag: "course_intro" },
        { name: "班课目录", index: "1", tag: "course_content" },
        { name: "售前咨询", index: "2", tag: "course_question" },
      ],
      currentIndex: "0",
      recommendClasses: [], //相关课程推荐byzuhuimin
      helpVisible: false,
      countDownText: "",
      countDownTimeStamp: -1,
      countDays: 0,
      countHours: 0,
      countMinutes: 0,
      countSeconds: 0,
      qrcode: {},
    };
  },
  created() {
    console.log(this.startTime);
    this.getCountDownClass();
  },
  mounted() {
    window.scrollTo(0, 0);
    getCourseBanner(2).then((res) => {
      if (res.success) {
        this.qrcode = res.result[0];
      }
    });
  },
  methods: {
    handleCommand(command) {
      this.bantxt = command;
      console.log(command);
      switch (command) {
        case "10天LeetCode经典100题":
          this.$router.push("/course/1");
          break;
        case "求职面试算法班":
          this.$router.push("/course/4");
          break;
        case "求职面试刷题班":
          this.$router.push("/course/5");
          break;
        case "北美SDE算法速成班":
          this.$router.push("/course/3");
          break;
        case "LeetCode千题视频讲解":
          this.$router.push("/course/2");
          break;
        case "北美SDE求职速成班":
          this.$router.push("/course/6");
          break;
        case "北美SDE求职旗舰班":
          this.$router.push("/course/7");
          break;
      }
    },
    changePage(index) {
      this.$router.push(`/course/${index}`);
    },
    onStick(data) {
      console.log(data);
      this.stickyToggle = data.sticked;
    },
    jumptoPrice() {
      this.TabActiveIndex = "course_intro";
      this.currentIndex = "0";
      this.$nextTick(() => {
        document.documentElement.scrollTop =
          this.$el.querySelector("#discountPrice").offsetTop - 60;
      });
    },
    timeCountDown(endDateStr) {
      if (endDateStr == -1) {
        return;
      }
      //结束时间
      var endDate = new Date(endDateStr);
      //当前时间
      var nowDate = new Date();
      //相差的总秒数
      if (endDate < nowDate) {
        window.location.reload();
      }
      var totalSeconds = parseInt((endDate - nowDate) / 1000);
      //天数
      var days = Math.floor(totalSeconds / (60 * 60 * 24));

      //取模（余数）
      var modulo = totalSeconds % (60 * 60 * 24);

      //小时数
      var hours = Math.floor(modulo / (60 * 60));

      modulo = modulo % (60 * 60);
      //分钟
      var minutes = Math.floor(modulo / 60);
      //秒

      var seconds = modulo % 60;

      this.countDays = days;
      this.countHours = hours;
      this.countMinutes = minutes;
      this.countSeconds = seconds >= 10 ? seconds : `0${seconds}`;

      setTimeout(() => {
        this.timeCountDown(endDateStr);
      }, 1000);
    },
    getCountDownClass() {
      getCountDownClass(this.br).then((res) => {
        if (res.success) {
          this.countDownTimeStamp = res.result;
          this.timeCountDown(this.countDownTimeStamp);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import url("../../assets/css/courses/common.css");
@import url("../../assets/css/courses/8-ObjectDesignCourse.css");
</style>
